@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
    --primary-color: #16A085;
    --background-color: #7a7ab3;
}

.outer-container {
    // background-color: #bbd4d9;
    // background-color: var(--background-color);
    background-image: linear-gradient(to right, #16A085 0%, #F4D03F 51%, #16A085 100%);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
}

.inner-container {
    background-color: #F8FBFF;
    width: 70%;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.resend-btn {
    width: fit-content;
    border: none;
    background: none;
}

.form-login {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
}

.left-content {
    text-align: center;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 3rem 3rem 0;
    align-items: center;

    h1 {
        margin-top: 10px;
        color: #666e74;
        font-size: x-large;
    }
}



.remember-me {
    margin: 0 !important;
}

.image-container {
    height: 80%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}



.right-form {
    width: 50%;
    padding: 1rem 0 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
        margin-bottom: 1.5rem;
        color: var(--primary-color);
        font-size: 2rem;
    }
}

.loginform {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    &>div {
        &>span {
            height: 45px;
        }
    }
}

.forgot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    a {
        text-decoration: none;
        color: var(--primary-color);
    }
}


.login-btn {
    margin-top: 0.5rem;
    height: 50px;
    color: white;
    font-size: medium;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-image: linear-gradient(to right, #16A085 0%, #F4D03F 51%, #16A085 100%);
    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;

}

.login-btn:hover {
    background-position: right center;
    border: none;
    color: #fff;
    text-decoration: none;
}
.register {
    display: flex;

    a {
        color: var(--primary-color);
        text-decoration: none;
    }
}
@media (max-width: 968px) {
    .outer-container {
        .inner-container {
            .form-login {
                flex-direction: column;

                .left-content {
                    display: none;
                }
            }

            .right-form {
                width: 100%;
            }
        }
    }
}

@media (max-width: 564px) {
    .outer-container {
        .inner-container {
            width: 90%;
            padding: 1rem .5rem;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .left-content {
                display: none;
            }

            .right-form {
                width: 100%;
                padding: .5rem;

                .register {
                    font-size: 12px;
                }

                .forgot {
                    font-size: 12px;
                }

                h2 {
                    margin-bottom: .5rem;
                    color: #42a092;
                    font-size: xx-large;
                }
            }

            .logo {
                text-align: center;
            }
        }
    }
}